import React, { useState, useEffect, useCallback } from 'react';
import { graphql, Link, useStaticQuery } from 'gatsby';
import MobileHeader from './mobileHeader';
import DesktopHeader from './desktopHeader';
import RightArrow from '../../images/icons/RightArrow.svg';
import { GetNavigationDataQuery } from '../../../graphql-types';
import { PlayState, Tween } from 'react-gsap';
import { useLocation } from '@reach/router';
import blacknessFullBloomArrow from '../../images/blackness-in-full-bloom/nav/blackness-full-bloom-arrow.png';
import blacknessFullBloom from '../../images/blackness-in-full-bloom/nav/blackness-full-bloom.jpg';

const GET_NAVIGATION_DATA = graphql`
  query GetNavigationData {
    allMenuItems(filter: { menu_name: { eq: "main" } }, sort: { fields: weight, order: DESC }) {
      nodes {
        title
        url
      }
    }
  }
`;

const Header: React.FC = () => {
  const navigation = useStaticQuery<GetNavigationDataQuery>(GET_NAVIGATION_DATA);
  const [opened, setOpened] = useState(false);
  const [playState, setPlayState] = useState(PlayState.stop);
  const [scrollTo, setScrollTo] = useState<string | null>(null);
  const location = useLocation();

  const isNavigationItemActive = useCallback(
    (path: string) => {
      if (location.pathname === path) {
        return 'active';
      }

      return '';
    },
    [location.pathname]
  );

  useEffect(() => {
    if (opened) {
      setPlayState(PlayState.play);
      document.body.style.overflow = 'hidden';
    } else {
      setPlayState(PlayState.reverse);
      document.body.style.overflow = 'visible';
    }
  }, [opened]);

  useEffect(() => {
    if (location.hash) {
      setScrollTo(location.hash);
    }
  }, [location.hash]);

  useEffect(() => {
    if (scrollTo) {
      setTimeout(() => {
        const section = document.querySelector(`${scrollTo}`);
        section?.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }, 100);
      setScrollTo(null);
    }
  }, [scrollTo]);

  const turnHamburgerMenu = (on: boolean) => {
    const navigationDesktop = document.getElementById('hamburguer-menu-desktop');
    const navigationMobile = document.getElementById('hamburguer-menu-mobile');

    if (on) {
      navigationDesktop?.style &&
        (navigationDesktop.style.opacity = '1') &&
        (navigationDesktop.style.visibility = 'visible');
      navigationMobile?.style &&
        (navigationMobile.style.opacity = '1') &&
        (navigationMobile.style.visibility = 'visible');
    } else {
      navigationDesktop?.style &&
        (navigationDesktop.style.opacity = '0') &&
        (navigationDesktop.style.visibility = 'hidden');
      navigationMobile?.style &&
        (navigationMobile.style.opacity = '0') &&
        (navigationMobile.style.visibility = 'hidden');
    }
  };

  useEffect(() => {
    const navigationMenu = document.getElementById('navigation-menu');

    navigationMenu?.addEventListener('scroll', () => {
      if (navigationMenu.scrollTop > 25) {
        turnHamburgerMenu(false);
      } else {
        turnHamburgerMenu(true);
      }
    });
  }, []);

  const renderNav = () => {
    const navItems = navigation.allMenuItems.nodes.map(({ url, title }) => (
      <li
        key={url}
        className={`mobile-max:text-lg mobile-max:leading-normal text-4lg leading-9 tablet:text-3vh tablet:leading-5vh desktop-medium:text-xl desktop-medium:leading-14-c flex justify-end items-center ${isNavigationItemActive(
          url ?? 'none'
        )}`}
      >
        <RightArrow className="opacity-0 mr-6 mobile-normal:mt-2 tablet:mt-0" />
        <Link to={url ?? ''} title={`Navigate to ${title}`}>{` ${title}`}</Link>
      </li>
    ));
    return (
      <>
        <Tween to={{ autoAlpha: 0.8 }} duration={0.5} playState={playState} ease="slow">
          <div
            className="fixed h-screen w-full bg-black z-10 opacity-0"
            onClick={() => {
              setOpened(false);
              turnHamburgerMenu(true);
            }}
          ></div>
        </Tween>
        <Tween to={{ autoAlpha: 1 }} duration={0.5} playState={playState} ease="slow">
          <nav
            id="navigation-menu"
            className="h-screen max-h-screen overflow-y-scroll text-right opacity-0 fixed z-20 bg-white right-0 flex flex-col tablet:justify-between hides-scroll-bar"
          >
            <div className="padding-lateral">
              <ul className="space-y-1 main-navigation-list mt-27 mb-6 laptop-standard:mt-35">
                {navItems}
              </ul>
              <div
                className="mt-7 w-full mb-5 laptop-standard:mb-0 mr-0 ml-0 relative cursor-pointer"
                style={{ height: '10vh' }}
              >
                <a href="https://blacknessinfullbloom.com/" target="_blank" rel="noreferrer">
                  <img
                    className="absolute hover:opacity-0 right-0"
                    src={blacknessFullBloom}
                    alt="Blackness in Fullbloom website"
                  />
                  <img
                    className="absolute opacity-0 hover:opacity-100 right-0"
                    src={blacknessFullBloomArrow}
                    alt="Blackness in Fullbloom website"
                  />
                </a>
              </div>
            </div>
            <div className="space-y-4 list-footer pb-10 padding-lateral">
              <div>
                <p className="mobile-max:text-xxxs mobile-max:leading-normal">
                  12901 W Jefferson Blvd
                  <br />
                  Los Angeles, CA 90066
                </p>
              </div>
              <div>
                <a
                  className="underline mobile-max:text-xxxs mobile-max:leading-normal"
                  href="https://goo.gl/maps/gSEFUAPAZjy5ZJCL6"
                  target="_blank"
                  rel="noreferrer"
                >
                  <p>View on map</p>
                </a>
              </div>
            </div>
          </nav>
        </Tween>
      </>
    );
  };

  return (
    <>
      {renderNav()}
      <div className="padding-lateral mobile:py-10 laptop-standard:pt-17 laptop-standard:pb-15">
        <MobileHeader opened={opened} setOpened={setOpened} />
        <DesktopHeader opened={opened} setOpened={setOpened} />
      </div>
    </>
  );
};

export default Header;
