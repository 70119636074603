/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { DLA } from '../constants';
import dlaSocialShare from '../images/DLA_SHARE_1200x630.jpg';
import { graphql, useStaticQuery } from 'gatsby';
import { SeoDataQuery } from '../../graphql-types';

const SEO_DATA = graphql`
  query SeoData {
    copyright: allMenuItems(
      filter: { menu_name: { eq: "footer-inline" }, title: { eq: "Copyright" } }
    ) {
      nodes {
        menu_name
        title
        description
        url
        children {
          ... on MenuItems {
            title
            description
            url
          }
        }
      }
    }
  }
`;

export interface SEOdata {
  title?: string;
  description?: string | null;
  author?: string;
  keywords?: string | null;
  twitter?: string;
  image?: string | null;
}

interface Props {
  lang?: string;
  seo?: SEOdata;
}

const SEO: React.FC<Props> = ({ lang = 'en', seo }) => {
  const seoData = useStaticQuery<SeoDataQuery>(SEO_DATA);

  /* This is a hack to fix the vendor search label */
  useEffect(() => {
    setTimeout(() => {
      const vendorSearchInput = document.getElementById('vendor-search-handler');
      const vendorSearchLabel = vendorSearchInput?.parentElement?.getElementsByTagName('label')[0];

      if (vendorSearchLabel && !vendorSearchLabel?.textContent) {
        vendorSearchLabel.textContent = 'Search';
      }
    }, 3000);
  }, []);

  return (
    <Helmet
      htmlAttributes={{
        lang: 'en',
      }}
    >
      <meta http-equiv="Content-Language" content={lang}></meta>
      <title>{seo?.title ? `${DLA.title} | ${seo?.title}` : DLA.title}</title>
      <meta name="title" content={seo?.title ?? DLA.title} />
      <meta name="description" content={seo?.description ?? DLA.description} />
      <meta name="author" content={seo?.author ?? DLA.author} />
      <meta name="keywords" content={seo?.keywords ?? 'read, deutsch'} />
      <meta name="copyright" content={seoData.copyright.nodes[0].description ?? ''} />
      <meta property="og:title" content={seo?.title ?? DLA.title} />
      <meta property="og:description" content={seo?.description ?? DLA.description} />
      <meta property="og:image" content={`${DLA.siteURL}${seo?.image ?? dlaSocialShare}`} />
      <meta name="twitter:card" content="summary" />
      <meta name="twitter:title" content={seo?.title ?? DLA.title} />
      <meta name="twitter:description" content={seo?.description ?? DLA.description} />
      <meta name="twitter:image" content={`${DLA.siteURL}${seo?.image ?? dlaSocialShare}`} />
      <script
        src="https://cdn.cookielaw.org/scripttemplates/otSDKStub.js"
        type="text/javascript"
        charSet="UTF-8"
        data-domain-script="156ef782-9cce-43a8-aa53-cd71c049520b"
      ></script>
      <script type="text/javascript">{'function OptanonWrapper() {}'}</script>
      <script type="text/javascript">
        {`
          if (window.location.hostname === 'deutschla.com' || window.location.hostname === 'www.deutschla.com' || window.location.hostname === 'deutschlosangeles.com' || window.location.hostname === 'www.deutschlosangeles.com' || window.location.hostname === 'stage.deutschla.com') {
            window.location.href = 'https://www.deutsch.com' + window.location.pathname + window.location.search;
          }
        `}
      </script>
    </Helmet>
  );
};

export default SEO;
