import React from 'react';
import { Link } from 'gatsby';
import LogoWide from '../../images/icons/DEUTSCH_LOGO_WIDE.svg';
import logoLive from '../../images/dla_faces_alive_03.gif';
import HamburgerMenu from './hamburgerMenu';

interface Props {
  opened: boolean;
  setOpened: React.Dispatch<React.SetStateAction<boolean>>;
}

const MobileHeader: React.FC<Props> = ({ opened, setOpened }) => {
  return (
    <div className="flex laptop-standard:hidden items-center justify-center justify-between">
      <div className="h-12 w-12">
        <Link to="/" title="Navigate to Home">
          <img className="h-full w-full" src={logoLive} alt="Deutsch LA Logo"></img>
        </Link>
      </div>
      <div className="h-10 w-40">
        <Link to="/" title="Navigate to Home">
          <LogoWide className="w-full" />
        </Link>
      </div>
      <div
        id="hamburguer-menu-mobile"
        className="pt-2 z-30"
        onClick={() => setOpened(!opened)}
        title="Open navigation Menu"
      >
        <HamburgerMenu open={opened} />
      </div>
    </div>
  );
};

export default MobileHeader;
