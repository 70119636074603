import React, { useEffect, useState } from 'react';
import { PlayState, Tween } from 'react-gsap';

const HAMBURGER_ANIMATION_DURATION = 0.2;

interface Props {
  open: boolean;
}

const HamburgerMenu: React.FC<Props> = ({ open }) => {
  const [playState, setPlayState] = useState(PlayState.stop);

  useEffect(() => {
    if (open) {
      setPlayState(PlayState.play);
    } else {
      setPlayState(PlayState.reverse);
    }
  }, [open]);

  return (
    <div className="cursor-pointer h-9 w-9 relative">
      <Tween
        playState={playState}
        to={{
          y: '11px',
          rotate: '135deg',
          width: '40px',
          height: '4px',
        }}
        duration={HAMBURGER_ANIMATION_DURATION}
      >
        <span className="hamburger-bar hamburger-bar-1"></span>
      </Tween>
      <Tween playState={playState} to={{ opacity: 0 }} duration={HAMBURGER_ANIMATION_DURATION}>
        <span className="hamburger-bar hamburger-bar-2"></span>
      </Tween>
      <Tween
        playState={playState}
        to={{
          y: '-8px',
          rotate: '-135deg',
          width: '40px',
          height: '4px',
        }}
        duration={HAMBURGER_ANIMATION_DURATION}
      >
        <span className="hamburger-bar hamburger-bar-3"></span>
      </Tween>
    </div>
  );
};

export default HamburgerMenu;
