/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable no-empty-function */

import React, { useState } from 'react';

interface ActiveModals {
  [key: string]: boolean;
}

const ActiveModalsContext = React.createContext<
  [ActiveModals, React.Dispatch<React.SetStateAction<ActiveModals>>]
>([{}, () => {}]);

interface ActiveModalsProviderProps {
  children: React.ReactNode;
}

const ActiveModalsProvider: React.FC<ActiveModalsProviderProps> = ({ children }) => {
  const [activeModals, setActiveModals] = useState<ActiveModals>({});

  return (
    <ActiveModalsContext.Provider value={[activeModals, setActiveModals]}>
      {children}
    </ActiveModalsContext.Provider>
  );
};

export { ActiveModalsProvider };

export default ActiveModalsContext;
