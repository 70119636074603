import React, { useEffect } from 'react';
import { useLocation } from '@reach/router';
import ReactHtmlParser from 'react-html-parser';
import { graphql, Link, useStaticQuery } from 'gatsby';
import dlaInverted from '../../images/HomePage/dla-inverted.gif';
import instagram from '../../images/icons/Footer_instagram_icon.svg';
import linkedin from '../../images/icons/Footer_linkedin_icon.svg';
import twitter from '../../images/icons/Footer_twitter_icon.svg';
import DLALogo from '../../images/HomePage/DLA_logo.png';
import { GetFooterDataQuery } from '../../../graphql-types';

const GET_FOOTER_DATA = graphql`
  query GetFooterData {
    allMenuItems {
      nodes {
        menu_name
        title
        description
        url
        children {
          ... on MenuItems {
            title
            description
            url
          }
        }
      }
    }
  }
`;

const socialIcon: Record<string, any> = {
  'Instagram': instagram,
  'LinkedIn': linkedin,
  'Twitter': twitter,
};

const BaseFooter: React.FC = () => {
  const [iframeHeight, setIframeHeight] = React.useState('h-82r tablet:h-74r');
  const location = useLocation();
  const footerData = useStaticQuery<GetFooterDataQuery>(GET_FOOTER_DATA);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.addEventListener('message', (e) => {
        if (e.data === 'makeMeSmaller') {
          setIframeHeight('h-42r');
        } else if (e.data === 'makeMeSmallerMobile') {
          setIframeHeight('h-23r');
          setTimeout(() => {
            const contactUsSection = document.getElementById('contact-us-form');
            contactUsSection?.scrollIntoView({ behavior: 'smooth', block: 'start' });
          }, 200);
        }
      });
    }
  }, []);

  const copyright = footerData.allMenuItems.nodes.find(
    (fields) => fields.menu_name === 'footer-inline' && fields.title === 'Copyright'
  );
  const footerInline = footerData.allMenuItems.nodes.filter(
    (fields) => fields.menu_name === 'footer-inline' && fields.title !== 'Copyright'
  );
  const footerSocial = footerData.allMenuItems.nodes.filter(
    (fields) => fields.menu_name === 'footer-social'
  );

  return (
    <div className="bg-black text-white" id="contact-us-form">
      <div className="container max-width padding-lateral laptop-standard-max:w-4/5 laptop-standard-max:ml-0">
        <div className="flex justify-between">
          <div className="pt-16 pb-6 laptop-standard:pt-25 laptop-standard:pb-14">
            <img alt={'Deutsch LA logo'} src={DLALogo} />
          </div>
        </div>
      </div>
      <div className="padding-lateral max-width">
        <div
          className="grid gap-x-27 laptop-standard-max:flex laptop-standard-max:flex-col laptop-standard:w-4/5"
          style={{ gridTemplateColumns: '70% 30%' }}
        >
          <iframe
            src={`${process.env.GATSBY_DRUPAL_URL}contact-form?mobile=true&location=${location.pathname}`}
            title="Contact Form"
            className={`w-full ${iframeHeight} laptop-standard:hidden`}
            scrolling="no"
          />
          <iframe
            src={`${process.env.GATSBY_DRUPAL_URL}contact-form?location=${location.pathname}`}
            title="Contact Form"
            className={`w-full ${iframeHeight} laptop-standard-max:hidden`}
            scrolling="no"
          />
          <div className="mb-15">
            <div className="space-y-4 list-footer pb-6 laptop-standard:pb-20 pt-3">
              <div>
                <p className="py-4 font-bold text-base leading-normal">FIND US</p>
                <p>
                  12901 W Jefferson Blvd
                  <br />
                  Los Angeles, CA 90066
                </p>
              </div>
              <div>
                <a
                  className="underline"
                  href="https://goo.gl/maps/gSEFUAPAZjy5ZJCL6"
                  target="_blank"
                  rel="noreferrer"
                >
                  <p>View on map</p>
                </a>
              </div>
              <div className="pt-6">
                <p>
                  16 Old Bailey,
                  <br />
                  London EC4M 7EG
                </p>
              </div>
              <div>
                <a
                  className="underline"
                  href="https://goo.gl/maps/TxTbzeyJs74XcLF69"
                  target="_blank"
                  rel="noreferrer"
                >
                  <p>View on map</p>
                </a>
              </div>
            </div>
            <div className="space-y-6 laptop-standard:space-y-8">
              <p className="py-4 font-bold">FOLLOW US</p>
              <ul className="flex list-none float-left !mt-2">
                {footerSocial.map(({ url, title }, index) => {
                  const SocialIcon = title ? socialIcon?.[title] : <></>;
                  return (
                    <li key={`social-link-${index}`} className="inline-block mr-2">
                      <a
                        href={url ?? ''}
                        target="_blank"
                        rel="noreferrer"
                        title={`Navigate to ${title}`}
                      >
                        <SocialIcon className="h-8" />
                      </a>
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="container max-width padding-lateral">
        <hr
          className="hidden laptop-standard:block mb-10"
          style={{ border: '1px solid #E5E5E5' }}
        />
        <div className="flex flex-wrap pb-14 laptop-standard:pb-25">
          <div className="flex-initial w-full">
            <div
              className="flex
              mobile:flex-wrap items-center
              laptop-standard:flex-no-wrap"
            >
              <div
                className="hidden laptop-standard:block
                laptop-standard:flex-initial laptop-standard:w-5/12 laptop-standard:order-1
                laptop-standard:mr-10 laptop-large:mr-20"
              >
                {copyright?.description}
              </div>
              <div
                className="mobile:flex-1 mobile:w-1/2 mobile:order-2
                body2 laptop-standard:hidden"
              >
                {ReactHtmlParser(copyright?.description?.replace(' — ', '<br/>') ?? '')}
              </div>
              <div
                className="mobile:flex-initial mobile:order-first mobile:w-full
                laptop-standard:flex-auto laptop-standard:w-19/80 laptop-standard:order-2 items-center mobile:pb-8 laptop-standard:pb-0"
              >
                <hr className="laptop-standard:hidden m-0 mb-9" />
                <ul className="body2 laptop-large:space-x-6 laptop-large:ml-px10 flex laptop-standard-max:justify-between laptop-standard-max:mx-2">
                  {footerInline.map(({ title, url }, index) => (
                    <li
                      key={`footer-li-${index}`}
                      className="flex pr-4"
                      title={`Navigate to ${title} page`}
                    >
                      {url?.startsWith('http') || url?.startsWith('www') ? (
                        <a href={url} target="_blank" rel="noreferrer">
                          {title}
                        </a>
                      ) : (
                        <Link to={url ?? ''}>{title}</Link>
                      )}
                    </li>
                  ))}
                </ul>
              </div>
              <div className="flex-initial hidden order-last laptop-standard:block">
                <img width="72px" height="72px" src={dlaInverted} alt={'Deutsch LA logo'} />
              </div>
              <div className="flex-initial  order-last laptop-standard:hidden">
                <img width="38px" height="38px" src={dlaInverted} alt={'Deutsch LA logo'} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BaseFooter;
