import React, { useContext, useEffect, useState } from 'react';
import Portal from './complements/portal';
import CloseIcon from '../images/icons/Close.svg';
import useDebounce from '../utils/useDebounce';
import ActiveModalsContext from '../context/activeModalsContext';

interface Props {
  id: string;
  children: React.ReactNode;
  onMessage?: (message: string) => void;
}

const Modal: React.FC<Props> = ({ id, onMessage, children }) => {
  const [activeModals, setActiveModals] = useContext(ActiveModalsContext);
  const [showModal, setShowModal] = useState(false);
  const shouldShowModal = useDebounce(showModal);

  useEffect(() => {
    if (typeof window !== 'undefined' && onMessage) {
      window.addEventListener('message', (e) => {
        if (typeof e.data === 'string') {
          onMessage(e.data);
          if (e.data.includes('close-modal')) {
            setShowModal(false);
          }
        }
      });
    }
  }, [onMessage]);

  useEffect(() => {
    if (activeModals[id]) {
      setShowModal(true);
    }
  }, [activeModals, id, setActiveModals]);

  const close = () => {
    setShowModal(false);
    setActiveModals({ ...activeModals, [id]: false });
  };

  return shouldShowModal ? (
    <Portal>
      <div className="modal-wrapper z-40" onClick={close}>
        <div className="modal-content relative">
          {children}
          <div
            className="absolute -top-12 right-0 laptop-standard:-right-12 transition duration-300 filter hover:invert-1 cursor-pointer"
            onClick={close}
          >
            <CloseIcon className="h-10 w-10" />
          </div>
        </div>
      </div>
    </Portal>
  ) : null;
};

export default Modal;
