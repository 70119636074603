import { Component, ReactNode } from 'react';
import ReactDOM from 'react-dom';

// Use a ternary operator to make sure that the document object is defined
const portalRoot = typeof document !== 'undefined' ? document.getElementById('portal') : null;

interface Props {
  children: ReactNode;
}

export default class Portal extends Component<Props> {
  el: Element | null;
  constructor(props: Props) {
    super(props);
    // Use a ternary operator to make sure that the document object is defined
    this.el = typeof document !== 'undefined' ? document.createElement('div') : null;
  }

  componentDidMount = (): void => {
    if (this.el) {
      portalRoot?.appendChild(this.el);
    }
  };

  componentWillUnmount = (): void => {
    if (this.el) {
      portalRoot?.removeChild(this.el);
    }
  };

  render(): ReactNode {
    const { children } = this.props;

    // Check that this.el is not null before using ReactDOM.createPortal
    if (this.el) {
      return ReactDOM.createPortal(children, this.el);
    }
    return null;
  }
}
