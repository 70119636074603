const DLA = {
  title: 'Deutsch LA',
  description: 'Deutsch LA is a data-inspired, culturally shaped creative studio.',
  author: 'Deutsch LA',
  address1: '12901 W Jefferson Blvd',
  address2: 'Los Angeles CA 90066',
  phone: '+1 310 862 3000',
  siteURL: 'https://deutschla.com',
  copyright: '©2021 DEUTSCH LOS ANGELES — All Rights Reserved',
};

const bgColors: Record<string, string> = {
  'noche': 'bg-black',
  'dia': 'all-text-black all-button-inverted',
  'dla-orange': 'bg-orange-600',
  'pacific': 'bg-purple-650',
  'azul': 'bg-blue-450',
  'lowes': 'bg-blue-850',
  'walmart': 'bg-blue-250',
  '23-me': 'bg-green-550',
  'dr-pepper': 'bg-red-1000',
  'petsmart': 'bg-red-650',
};

export { DLA, bgColors };
