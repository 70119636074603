import React from 'react';
import Modal from './modal';
import { navigate } from '@reach/router';

const StaticModals: React.FC = () => {
  return (
    <>
      <Modal
        id="film-reel-form"
        onMessage={(message) => {
          if (message && message.includes('get-in-touch')) {
            navigate('#contact-us-form');
          }
        }}
      >
        <iframe
          src={`${process.env.GATSBY_DRUPAL_URL}film-reel-form?mobile=true`}
          title="Film Reel Form"
          className="laptop-standard:hidden w-screen h-70vh"
        />
        <iframe
          src={`${process.env.GATSBY_DRUPAL_URL}film-reel-form`}
          title="Film Reel Form"
          className="laptop-standard-max:hidden w-91 h-83"
          scrolling="no"
        />
      </Modal>
    </>
  );
};

export default StaticModals;
