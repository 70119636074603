import React from 'react';
import Header from './header/header';
import BaseFooter from './footer/baseFooter';
import SEO, { SEOdata } from '../components/seo';
import '../styles/_layout.css';
import 'react-modal-video/scss/modal-video.scss';
import StaticModals from './staticModals';
import { ActiveModalsProvider } from '../context/activeModalsContext';

interface Props {
  seo: SEOdata;
}

const Layout: React.FC<Props> = ({ seo, children }) => {
  return (
    <ActiveModalsProvider>
      <div>
        <SEO seo={seo} />
        <Header />
        <StaticModals />
        <div className="relative">
          <main>{children}</main>
        </div>
        <BaseFooter />
      </div>
    </ActiveModalsProvider>
  );
};

export default Layout;
